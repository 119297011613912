import React from 'react'
import { navigate } from 'gatsby-link'

import {
  removeArrayDuplicates,
  formatStringByNumber,
  getDateRangeArr,
  sortArrayByTitle
} from '../lib/helpers'

import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import ClearIcon from '@material-ui/icons/Clear'

import SearchResultLink from '../mui-components/SearchResultLink/SearchResultLink'
import NonExpansiblePanel from '../mui-components/NonExpansiblePanel/NonExpansiblePanel'

// Search component
const analogyFinderResults = props => {
  const episodes = props.episodes.nodes
  let filteredEpisodes = []
  let showReset = false

  // Get all url params, so we can iterate through them later
  const urlParamsAll = new URLSearchParams(props.location.search)

  // Prepare episodes array, which match selected AF attributes
  episodes.forEach(ep => {
    // We need to make sure, episode matches all selected filters
    const matches = []

    // Iterate through url params
    // param[0] would be AF attribute slug, e.g. afaGeneralObjectives
    // param[1] would be comma separated selected values
    for (const param of urlParamsAll) {
      // We need to have exactly 2 values in the param array (as described above)
      if (param.length === 2) {
        // There can be multiple values for one url parameter, so we need to split them
        // Eg. afaGeneralObjectives=id1,id2
        const paramsArr = param[1].split(',')
        // Remove empty values
        const filteredParamsArr = paramsArr.filter(item => item !== '')

        if (filteredParamsArr.length > 0) {
          // We are using filters, show reset button
          showReset = true

          // Episode itself can also have multiple values of the same AF attribute
          // Basically, we need to check if the values from filteredParamsArr matches some of the episode attribute values
          filteredParamsArr.forEach(p => {
            matches.push(
              ep[param[0]].some(epAfaAttr => {
                return epAfaAttr && epAfaAttr.id === p
              })
            )
          })
        }
      }
    }

    // Check if all array values are true, which means episode matches all filtered params
    const allMatches = matches.length > 0 ? matches.every(val => val === true) : false
    // Then, we can push this episode to our filtered array
    if (allMatches) {
      filteredEpisodes.push(ep)
    }
  })

  // Get rid of duplicates
  filteredEpisodes = removeArrayDuplicates(filteredEpisodes, 'id')

  // Sort episodes by title
  filteredEpisodes = sortArrayByTitle(filteredEpisodes, 'fullTitle')

  // Prepare links for filtered episodes
  const filteredEpisodesLinks = []
  filteredEpisodes.forEach((item, index) => {
    filteredEpisodesLinks.push(
      <SearchResultLink
        to={item.path}
        title={item.fullTitle}
        subtitle={getDateRangeArr(item.startDate, item.endDate)}
        key={index}
      />
    )
  })

  return (
    <>
      <NonExpansiblePanel
        title="Results"
        subtitle={
          filteredEpisodes.length + ' ' + formatStringByNumber('episode', filteredEpisodes.length)
        }
        highlight
      >
        {filteredEpisodesLinks.length > 0
          ? filteredEpisodesLinks
          : 'No episodes found with selected criteria. Please try different ones.'}
      </NonExpansiblePanel>

      {showReset ? (
        <Box textAlign="center" pt={2}>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ClearIcon />}
            size="large"
            onClick={() => {
              // Reset all filters by removing url args and navigating to /analogy-finder/ page
              navigate(props.location.pathname)
            }}
          >
            Reset all filters
          </Button>
        </Box>
      ) : null}
    </>
  )
}

export default analogyFinderResults
