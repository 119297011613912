import React from 'react'
import { graphql } from 'gatsby'

import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../components/layout'

import AnalogyFinderResults from '../components/analogy-finder-results'

export const query = graphql`
  query AnalogyFinderQuery {
    allSanityAfaGeneralObjective(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    allSanityAfaOtherPolicyInstrument(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    allSanityAfaOtherSanction(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    allSanityAfaPotentialImpact(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    allSanityAfaEffectiveness(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    allSanityAfaSanctionPurpose(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    allSanitySanctionCategory(sort: { fields: order }) {
      nodes {
        title
        id
      }
    }
    episodes: allSanityEpisode(sort: { fields: episodeNumber }) {
      nodes {
        id
        fullTitle
        path
        startDate(formatString: "MMM-YYYY")
        endDate(formatString: "MMM-YYYY")
        afaGeneralObjectives {
          id
          title
        }
        afaOtherPolicyInstruments {
          title
          id
        }
        afaOtherSanctions {
          title
          id
        }
        afaPotentialImpacts {
          title
          id
        }
        afaEffectiveness {
          title
          id
        }
        afaSanctionPurposes {
          title
          id
        }
        afaSanctionTypes {
          title
          id
        }
      }
    }
  }
`

const AnalogyFinderPage = props => {
  const { data, errors } = props
  const episodes = data.episodes

  const title = 'Analogy Finder'

  const tabSections = [
    {
      type: 'allSanityAfaGeneralObjective',
      slug: 'afaGeneralObjectives',
      title: 'General Objectives'
    },
    {
      type: 'allSanityAfaSanctionPurpose',
      slug: 'afaSanctionPurposes',
      title: 'Sanction Purposes'
    },
    {
      type: 'allSanitySanctionCategory',
      slug: 'afaSanctionTypes',
      title: 'Sanction Types'
    },
    {
      type: 'allSanityAfaPotentialImpact',
      slug: 'afaPotentialImpacts',
      title: 'Potential impact on population'
    },
    {
      type: 'allSanityAfaEffectiveness',
      slug: 'afaEffectiveness',
      title: 'Effectiveness'
    },
    {
      type: 'allSanityAfaOtherSanction',
      slug: 'afaOtherSanctions',
      title: 'Other Sanctions in Place'
    },
    {
      type: 'allSanityAfaOtherPolicyInstrument',
      slug: 'afaOtherPolicyInstruments',
      title: 'Other policy instruments used'
    }
  ]

  const tabsData = []
  tabSections.forEach(tabSection => {
    tabsData.push({
      label: tabSection.title,
      data: data[tabSection.type],
      slug: tabSection.slug
    })
  })

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  return (
    <Layout title={title} {...props} analogyTabsData={tabsData}>
      <SEO title={title} />
      <AnalogyFinderResults episodes={episodes} location={props.location} />
    </Layout>
  )
}

export default AnalogyFinderPage
